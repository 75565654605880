import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import firebase from './plugins/firebase'
import axios from 'axios'
Vue.prototype.$axios = axios

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  fireabse: firebase(),
  render: h => h(App)
}).$mount('#app')

// イベント設定用関数
function noScroll(event) {
  event.preventDefault()
}

// スクロール禁止
Vue.prototype.$noScroll = function () {
  // SP
  document.addEventListener('touchmove', noScroll, {
    passive: false
  })
  // PC
  document.addEventListener('mousewheel', noScroll, {
    passive: false
  })
}

// スクロール禁止を解除
Vue.prototype.$returnScroll = function () {
  // SP
  document.removeEventListener('touchmove', noScroll, {
    passive: false
  })
  // PC
  document.removeEventListener('mousewheel', noScroll, {
    passive: false
  })
}

//jqueryを使えるように
import jQuery from 'jquery'
global.jquery = jQuery
global.$ = jQuery
window.$ = window.jQuery = require('jquery')