// これも追加！
// Your web app's Firebase configuration

export default {
  apiKey: "AIzaSyBvpC_8y40O39kFeVXqdPjXz9S8x890Mm8",
  authDomain: "penguin-system.firebaseapp.com",
  projectId: "penguin-system",
  storageBucket: "penguin-system.appspot.com",
  messagingSenderId: "40697978107",
  appId: "1:40697978107:web:1caa81c6bb2f62d5c9645e"
};