import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'

Vue.use(VueRouter)
// Vue.use(createRouter)

const routes = [{
    path: '/:aquarium',
    // name: 'Home',
    // component: Home
    component: () => import( /* webpackChunkName: "about" */ '../views/Form.vue'),
    props: true
  },
  {
    path: '/:aquarium/ranking',
    name: 'Ranking',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "about" */ '../views/Ranking.vue'),
    props: true
  },
  {
    path: '/:aquarium/form',
    name: 'Form',
    component: () => import( /* webpackChunkName: "about" */ '../views/Form.vue'),
    props: true
  },
  {
    path: '/:aquarium/peninfo',
    name: 'PenInfo',
    component: () => import( /* webpackChunkName: "about" */ '../views/PenInfo.vue'),
    props: true
  },
  {
    path: '/:aquarium/draw-penguin',
    name: 'DrawPenguin',
    component: () => import( /* webpackChunkName: "about" */ '../views/DrawPenguin.vue'),
    props: true
  },
  {
    path: '/:aquarium/pen-detail',
    name: 'pen-detail',
    component: () => import( /* webpackChunkName: "about" */ '../views/PenguinDetail.vue'),
    // props: true
    props: route => ({
      aquarium: route.params.aquarium,  // URLからidを取得
      info: route.params.info,  // pushしたオブジェクトからinfoを取得
      rank: route.params.rank,  // pushしたオブジェクトからrankを取得
      all_info: route.params.all_info  // pushしたオブジェクトからall_infoを取得
  })
  },
  {
    path: '/:aquarium/list',
    name: 'List',
    component: () => import( /* webpackChunkName: "about" */ '../views/List.vue'),
    props: true
  },
  {
    path: '/:aquarium/collection',
    name: 'Collection',
    component: () => import( /* webpackChunkName: "about" */ '../views/Collection.vue'),
    props: true
  },
  {
    path: '/', // ルートにアクセスされた場合
    redirect: '/sunshine'
  },
  {
    path: '//list', // リロードとかでsunshineが消えたとき用
    redirect: '/sunshine/list' // ハッシュルーティングで /#/sunshine にリダイレクト
  },
  {
    path: '//draw-penguin', // リロードとかでsunshineが消えたとき用
    redirect: '/sunshine/draw-penguin' // ハッシュルーティングで /#/sunshine にリダイレクト
  },
  {
    path: '//form', // リロードとかでsunshineが消えたとき用
    redirect: '/sunshine/form' // ハッシュルーティングで /#/sunshine にリダイレクト
  },
]

const router = new VueRouter({
  routes,
  scrollBehavior(to, from, savedPosition) {
    // if (savedPosition) {
    //   return savedPosition
    // } else {
    //   return {
    //     top: 0
    //   }
    // }
    if (savedPosition) {
      return savedPosition
    } else {
      return {
        x: 0,
        y: 0,
        top: 0
      }
    }
  },
})

export default router