import { initializeApp } from 'firebase/app'
import { getAuth, onAuthStateChanged} from 'firebase/auth'
import config from '../firebase.config'
import store from '../store'

initializeApp(config)
const auth = getAuth()

export default () => {
  return new Promise((resolve) => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        store.commit("setUser", {
          name: user.displayName,
          mail: user.email,
          image: user.photoURL,
          uid: user.uid,
        });
        console.log("ログインしました");
        // this.sendUserData(user.displayName, user.email);
        store.dispatch("sendUserData", {name: user.displayName, uid: user.uid});//!!!!!!!!!!
      } else {
        store.commit("setUser", {name: "guest", uid: "guest"});
        console.log("ログアウトしました");
      }
      resolve();
    });
  })
}